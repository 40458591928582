import React, { useState, useEffect } from 'react';
import { Star } from 'lucide-react';
import { useNavigate } from "react-router-dom";
import Header from '../components/HeaderComponent';
import '../styles/App.css'

const HomePage = () => {
  const [theme, setTheme] = useState('dark');
  const [searchParams, setSearchParams] = useState({ location: '', state: '' });
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [filteredProfiles, setFilteredProfiles] = useState([]);
  const navigate = useNavigate();

  const toggleTheme = () => {
    const newTheme = theme === 'dark' ? 'light' : 'dark';
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
  };

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      setTheme(savedTheme);
    }

    // Fetch states from IBGE API
    fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
      .then(response => response.json())
      .then(data => {
        const stateList = data.map(state => ({ id: state.id, name: state.nome, uf: state.sigla }));
        setStates(stateList);
      })
      .catch(error => console.error('Erro ao buscar estados:', error));
  }, []);

  useEffect(() => {
    if (searchParams.state) {
      // Fetch cities for the selected state
      fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${searchParams.state}/municipios`)
        .then(response => response.json())
        .then(data => {
          const cityList = data.map(city => city.nome);
          setCities(cityList);
        })
        .catch(error => console.error('Erro ao buscar cidades:', error));
    }

    // Filter profiles by state abbreviation
    const filtered = mockProfiles.filter(profile =>
      profile.location.toLowerCase().includes(searchParams.state.toLowerCase())
    );
    setFilteredProfiles(filtered);
  }, [searchParams.state]);

  const mockProfiles = [
    { id: 1, name: 'Ana Silva', location: 'São Paulo, SP', rating: 4.9, price: 'R$ 300/hora', image: 'https://i.pinimg.com/736x/c9/70/df/c970dfaf7eebcc5ac9ac13cec59dd259.jpg' },
    { id: 2, name: 'Beatriz Lima', location: 'Belo Horizonte, MG', rating: 4.8, price: 'R$ 500/hora', image: 'https://cdn.goenhance.ai/user/2024/07/19/c0c1400b-abc2-4541-a849-a7e4f361d28d_0.jpg' },
    { id: 3, name: 'Julia Santos', location: 'Florianópolis, SC', rating: 5.0, price: 'R$ 600/hora', image: 'https://s2-oglobo.glbimg.com/95Vk-SbCHPSmi7ggBr34knNEdyA=/1200x/smart/filters:cover():strip_icc()/i.s3.glbimg.com/v1/AUTH_da025474c0c44edd99332dddb09cabe8/internal_photos/bs/2023/A/5/BkXhBWR0Cjf1pw9XDJkw/whatsapp-image-2023-11-23-at-18.52.27.jpeg' },
  ];

  useEffect(() => {
    const filtered = mockProfiles.filter(profile =>
      profile.location.toLowerCase().includes(searchParams.location.toLowerCase())
    );
    setFilteredProfiles(filtered);
  }, [searchParams.location]);

  function handleNavigateProfile(id) {
    window.location.href=`/profile?id=${id}`;
  }

  const handleSearchChange = (e) => {
    setSearchParams({ ...searchParams, location: e.target.value });
  };

  const handleStateChange = (e) => {
    setSearchParams({ ...searchParams, state: e.target.value, location: '' });
  };

  return (
    <div className={`min-h-screen ${theme === 'dark' ? 'bg-zinc-900 text-zinc-100' : 'bg-gray-50 text-gray-900'}`}>
      <Header theme={theme} toggleTheme={toggleTheme} />

      <div className="pt-16">
        <div className={`${theme === 'dark' ? 'bg-gradient-to-r from-zinc-800 to-zinc-900' : 'bg-gradient-to-r from-violet-100 to-white'} text-white py-16`}>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center">
              <h2 className={`text-4xl font-bold mb-4 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
                Encontre a companhia perfeita para você!
              </h2>
              <p className={`text-xl mb-8 ${theme === 'dark' ? 'text-zinc-300' : 'text-gray-600'}`}>
                Profissionais verificadas para acompanhamento em eventos sociais
              </p>
            </div>

            <div className={`${theme === 'dark' ? 'bg-zinc-800' : 'bg-white'} rounded-lg p-4 shadow-2xl max-w-3xl mx-auto`}>
              <div className="grid grid-cols-1 gap-4">
                <div>
                  <label className={`block text-sm font-medium mb-2 ${theme === 'dark' ? 'text-zinc-300' : 'text-gray-700'}`}>
                    Estado
                  </label>
                  <select
                    value={searchParams.state}
                    onChange={handleStateChange}
                    className={`w-full p-2 rounded-md ${theme === 'dark' ? 'bg-zinc-700 text-white border-zinc-700 focus:ring-violet-600' : 'bg-gray-100 text-gray-900 border-gray-300 focus:ring-violet-500'}`}
                  >
                    <option value="">Selecione um estado</option>
                    {states.map(state => (
                      <option key={state.id} value={state.uf}>{state.name}</option>
                    ))}
                  </select>
                </div>
                <div>
                  <label className={`block text-sm font-medium mb-2 ${theme === 'dark' ? 'text-zinc-300' : 'text-gray-700'}`}>
                    Cidade
                  </label>
                  <input
                    type="text"
                    value={searchParams.location}
                    onChange={handleSearchChange}
                    list="cities"
                    className={`w-full p-2 rounded-md ${theme === 'dark' ? 'bg-zinc-700 text-white border-zinc-700 focus:ring-violet-600' : 'bg-gray-100 text-gray-900 border-gray-300 focus:ring-violet-500'}`}
                    placeholder="Digite sua cidade"
                    disabled={!searchParams.state}
                  />
                  <datalist id="cities">
                    {cities.map((city, index) => (
                      <option key={index} value={city} />
                    ))}
                  </datalist>
                </div>
              </div>
              <button className={`w-full mt-4 search-button ${theme === 'dark' ? 'bg-violet-700 text-white hover:bg-violet-600' : 'bg-violet-600 text-white hover:bg-violet-700'}`}>
                Buscar
              </button>
            </div>
          </div>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <h3 className={`text-2xl font-bold mb-8 ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>
            Perfis Destacados
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {filteredProfiles.map(profile => (
              <div key={profile.id} className={`${theme === 'dark' ? 'bg-zinc-800 border-zinc-700' : 'bg-white border-gray-200'} rounded-lg shadow-2xl overflow-hidden`}>
                <div className="w-full h-64">
                  <img src={profile.image} alt={profile.name} className="object-cover w-full h-full" />
                </div>
                <div className="p-4">
                  <div className="flex justify-between items-center mb-2">
                    <h4 className={`text-lg font-semibold ${theme === 'dark' ? 'text-white' : 'text-gray-900'}`}>{profile.name}</h4>
                    <div className="flex items-center">
                      <Star className="w-5 h-5 text-yellow-400 fill-current" />
                      <span className={`ml-1 ${theme === 'dark' ? 'text-zinc-300' : 'text-gray-600'}`}>{profile.rating}</span>
                    </div>
                  </div>
                  <p className={`text-sm mb-4 ${theme === 'dark' ? 'text-zinc-400' : 'text-gray-500'}`}>{profile.location}</p>
                  <div className="flex justify-between items-center">
                    <span className={`font-semibold ${theme === 'dark' ? 'text-violet-400' : 'text-violet-600'}`}>{profile.price}</span>
                    <button onClick={() => handleNavigateProfile(profile.id)} className={`px-4 py-2 rounded-md ${theme === 'dark' ? 'bg-violet-700 text-white hover:bg-violet-600' : 'bg-violet-600 text-white hover:bg-violet-700'}`}>
                      Ver Perfil
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
